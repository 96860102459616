import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ProductActions} from '@wix/wixstores-client-storefront-sdk/dist/es/src/product-actions/ProductActions';
import {
  PageMap,
  AddToCartActionOption,
  STORAGE_PAGINATION_KEY,
  BiButtonActionType,
} from '@wix/wixstores-client-core/dist/es/src/constants';
import {IProductWidgetDTO} from '../types/app-types';
import {IWidgetControllerConfig} from '@wix/native-components-infra/dist/src/types/types';
import {FedopsLogger} from '@wix/fedops-logger';
import {IStoreFrontNavigationContext} from '@wix/wixstores-client-core/dist/es/src/types/site-map';
import {origin} from '../constants';
import {hasSubscriptionPlans} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';

export class ProductService {
  private readonly productActions: ProductActions;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly compId: IWidgetControllerConfig['compId'],
    private readonly externalId: string,
    private readonly fedopsLogger: FedopsLogger
  ) {
    this.productActions = new ProductActions(this.siteStore);
  }

  private async trackAddToCartBI(product) {
    const eventData = {
      buttonType: BiButtonActionType.AddToCart,
      appName: 'productWidgetApp',
      hasOptions: false,
      productId: product.id,
      isNavigateCart: this.siteStore.cartActions.shouldNavigateToCart(),
      navigationClick: this.siteStore.cartActions.shouldNavigateToCart() ? 'cart' : 'mini-cart',
      productType: product.productType,
      origin,
    };

    return this.siteStore.biLogger.clickOnAddToCartSf(eventData);
  }

  private hasSubscriptions(product: IProductWidgetDTO) {
    return hasSubscriptionPlans(product);
  }

  private storeNavigationHistory() {
    const pageId = this.siteStore.siteApis.currentPage.id;
    const history: IStoreFrontNavigationContext = {
      pageId,
      paginationMap: [],
    };
    this.siteStore.storage.local.setItem(STORAGE_PAGINATION_KEY, JSON.stringify(history));
  }

  public handleClick(product: IProductWidgetDTO, navigate: boolean): Promise<any> {
    const customTextFields = undefined;
    const selectionIds = undefined;
    const quantity = 1;

    if (navigate) {
      this.storeNavigationHistory();

      return this.siteStore.navigate(
        {
          sectionId: PageMap.PRODUCT,
          state: product.urlPart,
          queryParams: undefined,
        },
        true
      );
    } else if (!product.hasOptions && !this.hasSubscriptions(product)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.trackAddToCartBI(product);
      this.fedopsLogger.interactionStarted('add-to-cart');
      return this.siteStore.cartActions.addToCart(
        product.id,
        selectionIds,
        quantity,
        customTextFields,
        AddToCartActionOption.MINI_CART,
        origin,
        () => {
          this.fedopsLogger.interactionEnded('add-to-cart');
        }
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.siteStore.biLogger.clickAddToCartWithOptionsSf({
        appName: 'productWidgetApp',
        origin,
        hasOptions: true,
        productId: product.id,
        productType: product.productType,
        navigationClick: this.siteStore.isMobile() ? 'product-page' : 'quick-view',
      });
      return this.productActions.quickViewProduct(
        product.id,
        'productwidget',
        product.urlPart,
        this.compId,
        this.externalId
      );
    }
  }
}
